const btnSwiperIncomeLast = document.getElementById('btnSwiperIncomeLast');
const btnSwiperIncomeExpected = document.getElementById('btnSwiperIncomeExpected');

const onClickSwiperIncome = ({ currentTarget }) => {
  const swiper = currentTarget.closest('.swiper-container');
  const slides = swiper.querySelectorAll('.swiper-slide');

  if (slides) slides.forEach(el => el.classList.toggle('swiper-slide--hidden'));
  swiper.swiper.update();

  if (btnSwiperIncomeLast.disabled) {
    btnSwiperIncomeLast.disabled = false;
    btnSwiperIncomeExpected.disabled = true;
  } else {
    btnSwiperIncomeLast.disabled = true;
    btnSwiperIncomeExpected.disabled = false;
  }
};

btnSwiperIncomeLast.addEventListener('click', onClickSwiperIncome);
btnSwiperIncomeExpected.addEventListener('click', onClickSwiperIncome);
